<template>
  <div 
    class="filter-select-item"
    :adaflag="item.label"
    :aria-label="item.label"
    aria-hidden="true"
    role="button"
    @click="clickItem($event)"
  >
    <span
      class="filter-select-item__inner haptics-feedback"
      :class="{ 
        'filter-select-item__inner_active': active,
      }"
    >
      <i 
        v-show="active && !isRW"
        class="filter-select-item__inner-cancel"
      >
      </i>
      <img
        v-if="dataType == 'img' && item.label_img"
        :src="transformImg({ img: item.label_img })"
        :alt="item.label"
      />
      <span class="filter-select-item__inner_text">{{ item.label }}</span>
      <!-- HOT角标 -->
      <span 
        v-if="item.hotMark"
        class="filter-select-item__hot-badge"
      >
        Hot
      </span>
    </span>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'

export default {
  name: 'SelectItem',
  inject: {
    constantData: {
      default: () => ({})
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    dataType: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssRight () {
      return this.constantData.GB_cssRight
    },
    isRW () {
      return this.constantData.IS_RW
    },
  },
  methods: {
    transformImg,
    clickItem (e) {
      if (this.disabled) return
      this.$emit('clickItem', e)
    }
  }
}
</script>

<style lang="less" scoped>
  .filter-select-item {
    color: #666;
    padding-right: .32rem;
    padding-bottom: .32rem;
    max-width: 100%;
    &__inner {
      position: relative;
      display: flex;
      padding: 0 .32rem;
      align-items: center;
      justify-content: center;
      height: .72rem;
      min-width: 1.44rem;
      border: 1px solid #e5e5e5;
      font-size: 12px;
      &_grey {
        background: #f6f6f6;
      }
      &_active {
        border-color: @sui_color_main;
        color: @sui_color_main;
      }
      &_text {
        white-space: nowrap;    // 不换行显示
        text-overflow: ellipsis; // 以...显示
        overflow: hidden;     // 超出部分隐藏
      }
      img {
        width: .34rem;
        height: .34rem;
        margin-right: .08rem;
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
    }
    &__inner-cancel {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 16px 16px 0;
      border-color: transparent @color_brand transparent transparent;
      &::before {
        position: absolute;
        top: -1px;
        .right(-18px);
        line-height: 1;
        content: 'x';
        width: 12px;
        height: 12px;
        line-height: 12px;
        color: #fff;
        text-align: center;
        transform: scale(0.8);
      }
    }
    &_hide {
      display: none;
    }
    &__hot-badge {
      font-size: 0.1867rem;
      height: 0.24rem;
      line-height: 0.24rem;
      padding: 0 0.08rem;
      margin-left: 0.08rem;
      border-radius: 0.0267rem;
      color: #FF411C;
      /*sh:begin*/
      background: #FFECE8;
      /*rw:begin*/
      background: #FCEDEF;
    }
  }
</style>

/**
 *  此mixin，为SideEachItem 和 SingleSelect 使用
 */

import { getCateResult } from '../common/format'

export default {
  data () {
    return {
      timer: 0,
      animItemText: ''
    }
  },
  methods: {
    // 处理筛选数据
    handleData (filterCates) {
      if (Array.isArray(filterCates)) {
        if (!filterCates.length) {
          this.cateLinks = []
          // this.filterCates = []
          return []
        }
        
        let curFilterData = []
        let cateLinks = []
        if (this.curValue) {
          const { filterData, nodeList } = getCateResult({ curValue: this.curValue, childNodeList: filterCates }) || {}
          curFilterData = filterData || filterCates // 匹配不到返回，全部的分类数据
          cateLinks = nodeList || []
        } else {
          this.cateLinks = []
          curFilterData = filterCates
        }

        // 店铺内 category 与 items 相互跳转 分类没有更新 去除了这个判断条件
        // if (this.cateLinks?.length != cateLinks.length) {
        // 延迟为了避免动画还未漂浮到位
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.cateLinks = cateLinks
        }, 300)
        // }

        return curFilterData
      }
    },
    // 点击筛选项
    clickItem ({ type, data, index }, e) {
      if (type == 'all') {
        this.clickCateItem({ data: { value: '' }, cancel: 0, paths: [] })
      } else if (type == 'link') {
        if (this.curValue != data.value) {
          // 如果点击非当前选中项，取当前点击项
          this.clickCateItem({
            data,
            cancel: 0,
            paths: this.cateLinks.slice(0, index + 1)
          })
        } else {
          // 如果点击当前选中项，取前一个
          this.clickCateItem({
            data: this.cateLinks[index - 1] || { value: '' },
            cancel: 1,
            originalValue: data.value,
            paths: index == 0 ? [] : this.cateLinks.slice(0, index)
          })
        }
        this.handleCateLinks()
      } else if (type == 'button') {
        if (this.curValue != data.value) {
          this.clickCateItem({ data, cancel: 0, paths: [...this.cateLinks, data] })
          if (data?.children?.length) {
            this.showAnim(data, e.currentTarget)
          }
        } else { // 反选
          this.clickCateItem({ data: this.cateLinks[this.cateLinks.length - 1] || { value: '' }, cancel: 1, paths: [...this.cateLinks] })
          const headerLink = this.$refs.sideNavMenu?.$refs.headerLink || this.$refs.headerLink // sideNavMenu为侧边筛选头部组件
          if (!headerLink) return

          const catebreadcrumbs = headerLink.$refs.catebreadcrumbs
          setTimeout(() => {
            catebreadcrumbs.scrollLeft = this.cssRight ? 0 : catebreadcrumbs.scrollWidth + 100
          }, 300)
        }
      }
    },
    // 移动动画
    showAnim (data, dom) {
      const headerLink = this.$refs.sideNavMenu?.$refs.headerLink || this.$refs.headerLink
      if (!headerLink) return
      
      const AnimItem = headerLink.$refs.cateAnimItem
      const breadcrumbsContainer = headerLink.$refs.catebreadcrumbs 

      // const fromTop = dom.offsetTop + breadcrumbsContainer.offsetHeight + window.innerWidth * 0.028
      const fromTop = dom.offsetTop + breadcrumbsContainer.offsetHeight
      let fromLeft = dom.offsetLeft
      let toLeft = headerLink.$refs.allLangPosition.offsetWidth

      if (this.cssRight) {
        fromLeft = dom.offsetParent.offsetWidth - dom.offsetWidth - fromLeft
        fromLeft = -fromLeft
        toLeft = -toLeft
      }
      requestAnimationFrame(() => {
        this.animItemText = data.label
        this.$nextTick(() => {
          AnimItem.style = `visibility: visible; transform: translate3d(${fromLeft}px,${fromTop}px,0px);`
          requestAnimationFrame(() => {
            AnimItem.style = `visibility: visible; transition: all .3s; transform: translate3d(${toLeft}px,0px,0px);`
            setTimeout(() => {
              // if (!this.cateLinks) {
              //   this.cateLinks = []
              // }
              // if (this.cateLinks.findIndex(v => v.value == data.value) == -1) {
              //   this.cateLinks = [...this.cateLinks, data]
              // }
              this.$nextTick(() => {
                AnimItem.style = ''
                breadcrumbsContainer.scrollLeft = this.cssRight ? 0 : breadcrumbsContainer.scrollWidth + 10
              })
            }, 300)
          })
        })
      })
    },
    // 处理头部选中的链式数据
    handleCateLinks () {
      if (this.curValue) {
        const jumpTo = this.cateLinks.findIndex(item => item.value == this.curValue)
        if (jumpTo != -1) this.cateLinks.splice(jumpTo + 1)
      } else {
        this.cateLinks = []
      }
    }
  }
}
